import { LangDefinition } from './no'

const en: LangDefinition = {
  locale: {
    language: 'Language',
    no: 'Norwegian',
    en: 'English',
    country: 'Country',
  },

  loading: 'Loading',
  open: 'Open',
  close: 'Close',
  yes: 'Yes',
  no: 'No',
  lastUpdated: 'Last updated',
  ago: 'ago',
  noResults: 'No results',

  home: {
    unauthorized: {
      welcome: 'Welcome to Yoga 2.0',
      firstTime: 'First time logging in?',
      intro:
        '1. Log inn with BankID\n2. Ask support / service department for access\n3. Log in again',
    },
    authorized: {
      welcome: 'Hi ${name}',
      goToSettings: 'Configure settings',
    },
  },
  select: {
    empty: 'No options',
    unselected: 'Choose an option…',
  },

  nav: {
    dashboard: 'Dashboard',
    consultations: 'Consultations',
    allConsultations: 'All consultations',
    myConsultations: 'My conslutations',
    prescriptions: 'Prescriptions',
    history: 'History',
    settings: 'Settings',
    help: 'Help',
    search: 'Search',
    signOut: 'Sign out',
    signIn: 'Sign in',
    closeSidebar: 'Close sidebar',
    openSidebar: 'Open sidebar',
  },
  auth: {
    notLoggedIn: 'Not logged in',
    signIn: 'Sign in',
    signOut: 'Sign out',
    insufficientPermissions: 'Missing permissions to view this page',
  },
  settings: {
    pageTitle: 'Settings',
    configureSettings: 'Configure settings',
    next: 'Next',
    unsupported: {
      speakerSelection:
        'Speaker selection is not currently supported in your browser',
    },
    browserRecommendation:
      'We recommend that you use Google Chrome for the best experience.',
    permissionError: 'Missing permissions. Update the browser settings.',
    info: {
      intro:
        'We need to verify that your camera, microphone and speaker are working before you can accept video calls.',
      done: 'Done',
    },
    deviceKinds: {
      camera: 'Camera',
      mic: 'Microphone',
      speaker: 'Speaker',
    },
    steps: {
      camera: {
        title: 'Choose Camera',
        preview: 'Preview',
        unavailable: 'No camera selected/available',
      },
      mic: {
        title: 'Choose Microphone',
        validated: 'Speak to test the microphone',
        awaitingValidation: 'The microphone works as it should',
        unavailable: 'No microphone selected/available',
      },
      speaker: {
        title: 'Choose speaker',
        unavailable: 'No speaker selected/available',
        demoSound: 'Play sound clip',
      },
    },
  },
  error: {
    header: 'Something went wrong:',
    retry: 'Try again',
    camera: 'An error has occured. Could not utilize camera.',
  },
  consultation: {
    notCompleted: 'Not handled',
    emptyList: 'No consultations waiting',
    emptyListHistory: 'No completed consultations',
    reclaimConfirm: 'Are you sure you want to reclaim this consultation?',
    mediaWarning:
      'Configure camera and audio devices correctly before starting a consultation.',
    changeSettings: 'Change settings',
    notCompletedWarning:
      'You have consultations that have not been completed. Please complete or refund via ',

    receipt: {
      summary: 'Summary',
      doctor: 'Practitioner',
      service: 'Service',
      status: 'Status',
      patient: 'Patient',
      partner: 'Partner',
      ssn: 'SSN',
      refundedAt: 'Refunded',
      duration: 'Duration',
      sum: 'Total',
      hours: 'Hours',
      minutes: 'Minutes',
      NOK: 'kr',
      consultationStatus: {
        initiated: 'Initialised',
        booked: 'Booked',
        payed: 'Paid',
        'no-show': 'No-show',
        serving: 'Serving',
        claimed: 'Claimed',
        refunded: 'Refunded',
        completed: 'Completed',
        cancelled: 'Cancelled',
      },
    },
    status: {
      unclaimed: 'Available patient',
      claimed: '${professionalName}',
      waited: 'Waited ${minutes} minutes',
      serving: 'Must be completed',
      completed: 'Completed ${time}',
      refunded: 'Refunded ${time}',
      cancelled: 'Cancelled ${time}',
    },
    modal: {
      reason: 'Reason',
      phone: 'Phone',
      goto: 'Open appointment',
      claim: 'Claim appointment',
      'claim-other': 'Reclaim appointment',
      unclaim: 'Unclaim appointment',
      appointmentId: 'Appointment id',
    },
    closeModal: {
      back: 'Back',
      title: 'Leave appointment?',
      message: 'Are you sure you want to exit this appointment',
    },
    sidebar: {
      time: 'Time',
      reason: 'Reason',
      child: 'Child',
      childSsn: 'Social security number',
      phone: 'Phone number',
      age: 'Age',
      gender: 'Biological gender',
      male: 'Male',
      female: 'Female',
      ssn: 'SSN',
      appointmentId: 'Appointment id',
      bookedBy: 'Booked by',
      relation: 'Relation',
      guardian: 'Parent'
    },
    video: {
      states: {
        joiningRoom: 'Preparing a video room ',
        sendingNotification: 'Sending a push notification',
        initiatingCall: 'Connecting to the mobile app',
        calling: 'Calling the patient',
      },
      counters: {
        initiatingCall: 'Waiting',
        calling: 'Waiting for answer',
      },
      actions: {
        joinRoom: 'Join room',
        call: 'Call',
        callAgain: 'Call again',
        cancelCall: 'Cancel',
        endConsultation: 'Hang up',
        exit: 'Exit',
        complete: 'Complete',
        completed: 'Completed',
      },
      buttons: {
        toggleMic: 'Microphone',
        toggleCam: 'Camera',
      },
      callResults: {
        ON_DECLINE: 'Patient declined',
        NOT_ANSWERED: "Patient didn't answer",
        TIMEOUT: 'Call timed out',
      },
      connecting: 'Connecting',
      connected: 'Connected',
      room: 'Connected to videoroom',
    },
    refund: {
      refunding: 'Refunding',
      refunded: 'Refunded',
      refund: 'Refund',
      cause: 'Cause',
      reason: 'Reason',
      reasonTitle: 'Add a comment',
      reasonPlaceholder: 'Help us understand the reason',
      cancel: 'Cancel',
      causes: {
        technicalError: 'Technical error',
        noAnswer: 'Unanswered call',
        other: 'Other',
      },
    },
    insurance: {
      insuranceCountryNotice: 'If the patient must have further treatment abroad, Support must send epicrisis to Gjensidige.',
    }
  },
  user: {
    missingName: '[Missing name]',
    missingPhone: '[Missing phone]',
  },

  prescription: {
    emptyList: 'No prescriptions waiting',
    emptyListHistory: 'No completed prescriptions',
    copyText: 'Copy all text',
    prescription: 'Prescription',

    claimedByOther: {
      title: 'This prescription is already claimed by another proffesional.',
      back: 'Back to list of prescriptions',
    },

    categories: {
      experiences: 'Previous experiences',
      guide: 'Guide',
      health: 'Health',
      details: 'Details',
      other: 'Other',
    },

    list: {
      claimed: 'My prescriptions — work in progress',
    },

    claimModal: {
      info: "It's important that you treat the order as other doctors won’t be able to access the prescription.",
      claim: 'Start treatment',
      cancel: 'Calcel',
      goto: 'Open prescription',
      cancelTreatment: 'Cancel treatment',
    },

    accept: 'Accept',
    reject: 'Reject',
    cancel: 'Cancel treatment',

    acceptModal: {
      title: 'Do you want to accept the prescription?',
      message:
        'Remember to send the e-prescription and fill out PasientSky before you confirm.',
      success: 'Close',
    },

    declineModal: {
      title: 'Do you want to reject the prescription?',
      message: "You can't undo this choice.",
    },

    uploadModal: {
      title:
        'Do you want to accept the prescription pu uploading the corresponding file?',
      message:
        'To approve the order you need to create a corresponding document in Fürst, download it and upload it here',
      uploadFile: 'Upload file',
      fileUploaded: 'File was successfully uploaded',
      wrongFile:
        'The information in the file does not correspond with the patients information. Try with another file',
    },

    columns: {
      patient: 'Patient',
      phone: 'Phone',
      ssn: 'SSN',
    },
    badge: {
      claimed: 'Processing',
    },
    details: 'Details',
    products: 'Products',
    questions: 'Questions',
    choices: 'Valg',

    age: 'Alder',
    bmi: 'BMI',
    unknown: 'Ukjent',

    product: {
      name: 'Product name',
      category: 'Category',
      duration: 'Duration',
    },
  },
}

export default en
